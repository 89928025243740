import { observer } from "mobx-react-lite";
import React, { FC, SyntheticEvent, useCallback, useRef } from "react";
import useStores from "../hooks";
import { H1 } from "./typo";

type Props = {
	onDismiss: () => void;
};

const ConversionDialog: FC<Props> = observer(({ onDismiss }) => {
	const { conversionDialogStore } = useStores();

	const timeMounted = useRef(Date.now());
	const emailRef = useRef<HTMLInputElement>(null);
	const termsRef = useRef<HTMLInputElement>(null);

	const handleClick = useCallback((e: SyntheticEvent) => {
		e.stopPropagation();
	}, []);

	const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
		async (e) => {
			e.preventDefault();

			const email = emailRef.current?.value;
			const subscribeNewsletter = termsRef.current?.checked;

			if (email) {
				conversionDialogStore.submit(
					email,
					subscribeNewsletter,
					timeMounted.current,
				);
			} else {
				// unreachable, because the UI enforces checking the box before submitting
			}
		},
		[conversionDialogStore],
	);

	return (
		<div
			onClick={handleClick}
			className="bg-white rounded-lg shadow-lg max-w-xl p-10"
		>
			<H1 className="pb-4">
				Lassen Sie sich Ihren persönlichen Nachhaltigkeitsbericht als PDF per
				E-Mail übermitteln
			</H1>
			{!conversionDialogStore.isDone ? (
				<form onSubmit={handleSubmit} className="flex flex-col space-y-4">
					{conversionDialogStore.isError && (
						<div className="rounded p-4 bg-red-200 text-red-900">
							<strong>Netzwerkfehler:</strong> Bitte überprüfen Sie Ihre
							Internetverbindung und probieren es noch einmal.
						</div>
					)}

					<input
						ref={emailRef}
						type="email"
						name="email"
						placeholder="E-Mail"
						className="flex items-stretch rounded-md disabled:opacity-50"
						required
						disabled={conversionDialogStore.isLoading}
					/>
					<div className="flex items-center">
						<label className="mb-0 text-sm">
							<input
								ref={termsRef}
								type="checkbox"
								name="terms"
								className="form-checkbox rounded h-5 w-5 text-green-600 mr-2 focus:ring-0 disabled:opacity-50"
								disabled={conversionDialogStore.isLoading}
							/>
							Ich möchte über aktuelle Aktionen und Partnerschaften informiert
							werden
						</label>
					</div>
					<div className="-mx-2 flex flex-row items-center justify-between">
						<button
							type="submit"
							className="font-display font-medium mx-2 text-white bg-green-600 px-4 py-2 rounded-md shadow-md disabled:opacity-50"
							disabled={conversionDialogStore.isLoading}
						>
							Senden
						</button>
						<button
							type="reset"
							className="font-display font-medium mx-2 text-green-600 px-4 py-2 disabled:opacity-50"
							onClick={onDismiss}
							disabled={conversionDialogStore.isLoading}
						>
							Nein, danke
						</button>
						{conversionDialogStore.isLoading && (
							<div className="mx-2">
								<svg
									className="animate-spin -ml-1 mr-3 h-5 w-5 text-green-600"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
								>
									<circle
										className="opacity-25"
										cx="12"
										cy="12"
										r="10"
										stroke="currentColor"
										strokeWidth="4"
									/>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									/>
								</svg>
							</div>
						)}
						<div className="flex-1"></div>
						<a
							className="font-display font-medium mx-2 text-green-600 px-2 py-2 disabled:opacity-50"
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.zusammen-nachhaltig.de/datenschutz"
						>
							Datenschutz
						</a>
					</div>
				</form>
			) : (
				<div className="space-y-4">
					<p className="text-base font-light">
						Sie erhalten in Kürze eine E-Mail.
					</p>
					<button
						onClick={onDismiss}
						className="text-white bg-green-600 px-4 py-2 rounded-md shadow-md"
					>
						Schließen
					</button>
				</div>
			)}
		</div>
	);
});

export default ConversionDialog;

import { observer } from "mobx-react-lite";
import { useState } from "react";
import { hints } from "../data/hints";
import useStores from "../hooks";
import { data } from "../data/data";
import { PartnerCard } from "./PartnerCard";
import { H1, H2 } from "./typo";
var classNames = require("classnames");

interface Props {
  sectionKey: string;
}
const Tab = (props: {
  title: string;
  onClick: () => void;
  isActive?: boolean;
}) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames(
        " text-gray-800 hover:bg-gray-200 px-3 py-2 font-display font-medium text-sm rounded-md",
        {
          "text-green-50 bg-green-600 hover:bg-green-700": props.isActive,
        }
      )}
    >
      {props.title}
    </button>
  );
};

export const Recommendations = observer(({ sectionKey }: Props) => {
  const store = useStores();
  const section = store.data[sectionKey];
  const filteredHints = hints[sectionKey].filter((hint) => {
    try {
      return hint.condition && hint.condition!(store.data);
    } catch (error) {
      return false;
    }
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const partners = store.getFilteredPartners(sectionKey);
  return (
    <div className="pb-4">
      <div className="sm:hidden">
        {/* <label htmlFor="tabs md:hidden">Wählen Sie eine Rubrik</label> */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-green-800 focus:border-green-600 border-gray-300 rounded-md"
        >
          {filteredHints.map((hint) => (
            <option>{hint.title}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4 pb-4" aria-label="Tabs">
          {filteredHints.map((hint) => (
            <Tab
              onClick={() => setSelectedTab(filteredHints.indexOf(hint))}
              title={hint.title}
              isActive={selectedTab === filteredHints.indexOf(hint)}
            ></Tab>
          ))}
        </nav>
      </div>
      <div className="border-b-2 pb-8">
        {filteredHints.length !== 0 && (
          <div className="inline-grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
            {filteredHints[selectedTab].hints.map((hint) => (
              <div className="flex flex-col bg-white shadow-sm p-4 min-h-0">
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mb-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    />
                  </svg>
                </div>
                <div className="pt-2 flex">{hint.text}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="border-b-2 pb-8">
        <div className="flex flex-col md:flex-row md:justify-between md:items-start my-8">
          <div>
            <H1 className="">Partner & Artikel</H1>
            <span className="pt-2">
              Diese Partner haben nachhaltige Angebote. Sie können diese nach
              PLZ filtern.
            </span>
          </div>
          <input
            type="number"
            name="plz"
            placeholder="PLZ"
            maxLength={5}
            min={0}
            value={store.zipCodeFilter}
            onChange={(event) => {
              const { value, maxLength } = event.target;
              const message = value.slice(0, maxLength);
              event.target.value = message;
              store.setZipCodeFilter(message);
            }}
            className="rounded-md mt-4 md:mt-0"
          />
        </div>
        <div className="h-auto grid grid-cols-1 md:grid-cols-3 grid-rows-auto gap-4">
          {partners.length === 0 ? (
            <span className="text-md text-gray-600">
              Keine Ergebnisse gefunden
            </span>
          ) : (
            partners.map((partner) => (
              <PartnerCard partner={partner} sectionKey={sectionKey} />
            ))
          )}
        </div>
      </div>
    </div>
  );
});

const EMMA_FORM_CONFIG = {
  action: "https://mailing.sparkasse.de/optin/optin/execute",
  method: "POST",
  charset: "utf-8",
  enctype: "application/x-www-form-urlencoded",
};

const HIDDEN_FIELDS = [
  ["account_id", "16502"],
  ["account_code", "2cH1Z"],
  ["optinsetup_id", "20"],
  ["optinsetup_code", "w8mbg"],
];
const HIDDEN_FIELDS_OPTIN = [
  ["account_id", "16502"],
  ["account_code", "2cH1Z"],
  ["optinsetup_id", "22"],
  ["optinsetup_code", "w8mbg"],
];

const TIMER_FIELD = ["ic", (n) => n * 17] as const;

const USER_FIELD_MAP = {
  email: "fields[1]",
};

export interface EmmaData {
  email: string;
  subscribeNewsletter: boolean;
}

function prepareFormData(data: EmmaData, timeMounted: number) {
  const fd = new URLSearchParams();

  const activeFields = data.subscribeNewsletter
    ? HIDDEN_FIELDS_OPTIN
    : HIDDEN_FIELDS;

  for (const field of activeFields) {
    fd.append(field[0], field[1]);
  }

  const secondsPassed = Math.floor((Date.now() - timeMounted) / 1000);
  fd.append(TIMER_FIELD[0], TIMER_FIELD[1](secondsPassed).toFixed(0));

  fd.append(USER_FIELD_MAP.email, data.email);

  return fd;
}

/**
 *
 * @param data email and preference
 * @param timeMounted unix timestamp of the form mount time
 */
export async function submitEmma(data: EmmaData, timeMounted: number) {
  const fd = prepareFormData(data, timeMounted);

  const { action, method, enctype } = EMMA_FORM_CONFIG;
  const response = await fetch(action, {
    mode: "no-cors",
    referrerPolicy: "no-referrer",
    method,
    headers: {
      "Content-Type": enctype,
    },
    body: fd,
  });

  // if (response.status !== 200) {
  //   throw new Error(`EMMA returned a http error code (${response.status})`);
  // }
}
